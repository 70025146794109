.marker-container {
  line-height: 1;
}

.active-marker {
  display: inline-block;
  width: 36px;
  height: 48px;
}

.inactive-marker {
  display: none;
  width: 22px;
  height: 28px;
}

.display-marker {
  display: inline-block !important;
}

.hide-marker {
  display: none !important;
}
