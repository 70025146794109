.partial-modal {
  --backdrop-opacity: var(--appo-modal-backdrop-opacity);
  --height: 75%;
  --border-radius: 16px;
  align-items: flex-end;

  &::part(content) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  ion-header {

    &::after {
      display: none;
    }
  }

  ion-toolbar {
    --min-height: 50px;
    --border-width: 0 !important;
    --background: var(--global-background-color);

    ion-title {
      padding: 0;
    }
  }

  ion-footer {
    // Replaced by global settings
    //padding-left: var(--appo-base-spacing);
    //padding-right: var(--appo-base-spacing);
    background-color: var(--global-background-color);
  }
}

// See node_modules/@ionic/angular/src/css/core.scss
ion-modal.modal-default.show-modal ~ ion-modal.modal-default {
  --backdrop-opacity: var(--appo-modal-backdrop-opacity);
  --box-shadow: none;
}
