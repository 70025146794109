/**
  When displaying the CreateAppointment component,
  the Ionic navigation adds ion-page-hidden to prev page making
  the screen to have full page with a single color. For this
  specific case, a transparent background is needed, so prevent
  this just for this page.
 */
ion-router-outlet appo-business-calendar.ion-page-hidden {
  display: flex !important;
}
