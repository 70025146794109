.option-hide-ios-icons {
  ion-icon.ios {
    display: none;
  }
}

.option-info-icon {
  ion-icon {
    font-size: 18px !important;
    --color: var(--main-color);
  }

  ion-icon.md {
    margin-right: 35px; // 32px default + (24px - icon width)
    margin-left: 3px;
  }
}

.option-button--disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--gray-color) !important;
}
