// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #4A65F5;
  --ion-color-primary-rgb: 74, 101, 245;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #4159d8;
  --ion-color-primary-tint: #5c74f6;

  // Color for blue buttons
  --ion-color-appo-blue: #526DFD;
  --ion-color-appo-blue-rgb: 82, 109, 253;
  --ion-color-appo-blue-contrast: #ffffff;
  --ion-color-appo-blue-contrast-rgb: 255, 255, 255;
  --ion-color-appo-blue-shade: #4860df;
  --ion-color-appo-blue-tint: #637cfd;

  // Color for white buttons
  --ion-color-appo-white: #ffffff;
  --ion-color-appo-white-rgb: 255, 255, 255;
  --ion-color-appo-white-contrast: #E6E8F3;
  --ion-color-appo-white-contrast-rgb: 230, 232, 243;
  --ion-color-appo-white-shade: #e0e0e0;
  --ion-color-appo-white-tint: #ffffff;

  // Danger (cancel button)
  --ion-color-appo-red: #FF175D;
  --ion-color-appo-red-rgb: 255, 23, 93;
  --ion-color-appo-red-contrast: #ffffff;
  --ion-color-appo-red-contrast-rgb: 255, 255, 255;
  --ion-color-appo-red-shade: #e01452;
  --ion-color-appo-red-tint: #ff2e6d;

  // Gray color
  --ion-color-appo-gray: #E6E8F3;
  --ion-color-appo-gray-rgb: 230, 232, 243;
  --ion-color-appo-gray-contrast: #000000;
  --ion-color-appo-gray-contrast-rgb: 0, 0, 0;
  --ion-color-appo-gray-shade: #caccd6;
  --ion-color-appo-gray-tint: #e9eaf4;

  // Green
  --ion-color-appo-green: #3DD683;
  --ion-color-appo-green-rgb: 61, 214, 131;
  --ion-color-appo-green-contrast: #ffffff;
  --ion-color-appo-green-contrast-rgb: 0, 0, 0;
  --ion-color-appo-green-shade: #36bc73;
  --ion-color-appo-green-tint: #50da8f;

  //  Appointia variables
  --ion-font-family: 'Roboto', sans-serif;
  --global-background-color: #f7f8fa;
  --main-color: #4A65F5;
  --text-color: #03093A;
  --gray-color: #7E8597;
  //--ion-border-color: var(--gray-color);

  // Favorites Icon
  --ion-color-favorites: #f8e2eb;
  --ion-color-favorites-rgb: 248, 226, 235;
  --ion-color-favorites-contrast: red;
  --ion-color-favorites-contrast-rgb: 255, 0, 0;
  --ion-color-favorites-shade: rgba(255, 23, 93, 0.1);
  --ion-color-favorites-tint: #f9e5ed;

  --appo-fav-buttons-size: 50px;
  --appo-fav-buttons-half-size: calc(var(--appo-fav-buttons-size) / 2);

  --appo-border-color: rgba(230, 232, 243, 1);
  --appo-generic-info-margin-top: 10px;
  --appo-border-radius: 16px;
  --appo-button-activated-opacity: 0.4;
  --appo-base-spacing: 20px;
  --appo-facebook-blue: #1877f2;

  // Logos
  --appo-logo-border-radius: 12px;
  --appo-logo-size-small: 24px;
  --appo-logo-size-big: 40px;

  --demo-text-color: #e01452;

  --appo-safe-area-bottom: var(--appo-base-spacing);
  --appo-safe-area-top: var(--appo-base-spacing);

  // ion-item
  --appo-ion-item-border-color: #E6E8F3;

  // Used for a modal over another modal for --backdrop-opacity prop.
  --appo-modal-backdrop-opacity: 0.4;

  --appo-whatsapp-color: rgb(0, 205, 97);
}

