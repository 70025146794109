/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/appo-themes";

@import "./theme/marker";
@import "./theme/mapbox-geocoder";
@import "./theme/spot-options";
@import "theme/current_location_marker";
@import "theme/rotate";
@import "theme/ion-slides";
@import "theme/modals";
@import "theme/create-appointment";

// activated, focused: => --ion-color-primary-shade
// background => primary
// hover => tint

.ion-display-flex {
  display: flex;
}

.ion-width-100 {
  width: 100%;
}

.ion-flex-direction-column {
  flex-direction: column;
}

.ion-flex-direction-row {
  flex-direction: row;
}

.ion-flex-not-shrink {
  flex-shrink: 0;
}

.ion-display-block {
  display: block;
}

.ion-width-100 {
  width: 100%;
}

.ion-height-100 {
  height: 100%;
}

body {
  background-color: var(--global-background-color);
}

ion-content {
  --background: var(--global-background-color);
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 0;
  --padding-bottom: var(--appo-safe-area-bottom) !important;
}

ion-footer {
  box-shadow: none !important;
  background: white;
  padding: 0 0 var(--ion-safe-area-bottom) 0;

  &::before {
    display: none;
  }

  &:has(appo-action-buttons-container) {
    padding-left: var(--appo-base-spacing);
    padding-right: var(--appo-base-spacing);
  }
}

html[has-notch=false] ion-modal ion-footer {
  padding-bottom: var(--appo-safe-area-bottom);
}

html[has-notch=true] :not(ion-modal) ion-footer {
  background: var(--global-background-color) !important;
}

.ion-footer-notch {
  background: var(--global-background-color) !important;
}

ion-list {
  background: transparent !important;
  padding: 0 !important;
}

ion-toolbar {
  --min-height: 70px;
  --padding-start: 20px;
  --padding-end: 20px;

  ion-buttons[slot="start"] {
    margin-left: 0;
  }

  ion-buttons[slot="end"] {
    margin-right: 0;
  }
}

.searchbar-input.sc-ion-searchbar-ios {
  //background: var(--ion-color-appo-blue) !important;
}

.searchbar-input {
  --border-radius: 10px;
}

.button-has-icon-only.button-clear {
  $padding: 8px;
  --padding-start: #{$padding} !important;
  --padding-end: #{$padding} !important;
  --padding-bottom: #{$padding} !important;
  --padding-top: #{$padding} !important;
}

ion-refresher {
  height: 100px; // Should match pullMin in ion-refresher
}

ion-refresher-content {
  --color: var(--ion-color-primary);

  .refresher-pulling-icon {
    color: var(--ion-color-primary) !important;
  }
}

ion-infinite-scroll-content {
  --color: var(--ion-color-primary);

  .infinite-loading {
    margin-bottom: 0;
  }
}

// Used with <a />
.link {
  text-decoration: none;

  :hover {
    color: var(--main-color) !important;
    text-decoration: underline;
  }
}

.link-disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.favorite {
  $icon-size: 20px;

  &--active {
    --border-style: none !important; // Removes 2 pixes from the total width;
    --padding-start: 6px !important;
    --padding-end: 6px !important;

    ion-icon {
      font-size: $icon-size !important;
    }
  }

  &--inactive {
    // It has border, so subtract 1px from each side
    --padding-start: 5px !important;
    --padding-end: 5px !important;

    ion-icon {
      font-size: $icon-size !important;
    }
  }
}

ion-button:focus {
  outline: 0 !important;
}

.ios .appointment-confirmed-btn {
  --round-btn-border-style: solid;
  --round-btn-border-color: #6F85FF;
  --round-background-color: transparent;
  --round-btn-color: white;

  ion-button.ion-activated {
    --border-color: white;
    --background: white;
    --background-color: white;
    --color-activated: var(--main-color);
    --background-activated: white;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

// This for appointment (user & business) cmp
.ios .appointment ion-item div[slot="end"] {
  margin-right: 0;
}

ion-skeleton-text {
  --background-rgb: var(--ion-color-primary-rgb);
  --border-radius: 4px;
}

// Remove angular router link blue border
*:focus {
  outline: none !important;
}


.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Hide header lines
ion-header {
  box-shadow: none !important;

  &::after {
    display: none;
  }
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

// Disable ion-item form validation
.item-interactive {
  --highlight-background: transparent !important;
}

ion-item.item-has-focus::part(native) {
  border-bottom-color: var(--appo-ion-item-border-color);
  caret-color: var(--color, black);
}

// Login screen
ion-item.item-fill-none.item-interactive.ion-touched.ion-invalid::part(native) {
  border-bottom-color: var(--appo-ion-item-border-color);
}

ion-item.sc-ion-input-md-h:not(.item-label), ion-item:not(.item-label) .sc-ion-input-md-h {
  --padding-start: 1px;
}

.professional-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-account-confirm-btn {
  color: var(--ion-color-appo-red) !important;
}

// Prevent Angular virtual scroll with ion-item long text overflow-x
.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

.business-created-success-popup {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: var(--appo-border-radius);
}

.gray-text {
  text-align: center;
  display: block;
  margin-top: 20px;
  color: var(--gray-color);
  font-size: 14px;
}

.base-card {
  border-radius: var(--appo-border-radius);
  border-color: var(--appo-border-color);
  border-width: 1px;
  border-style: solid;
  --ripple-color: var(--ion-color-appo-blue);
  margin: 0 0 10px 0;
  box-shadow: none;
}

.weekly-availability-modal {
  --height: 95%;
}

.ion-searchbar-blue {
  --background: var(--ion-color-appo-blue);
  --color: white;
  --icon-color: white;
  --placeholder-color: white;
  --placeholder-opacity: 1;
  --box-shadow: none;
  padding: 0;

  input {
    caret-color: white;
  }
}

ion-toolbar:has(.ion-searchbar-blue) {
  --padding-top: 20px;
  --padding-bottom: 20px;

  .ion-searchbar-blue {
    padding-bottom: 0;
    margin-right: 10px;
  }
}

.ion-btn-outline-small {
  --border-width: 0.5px;
  font-weight: 400;
}

// Fix for ios because the ion-title on ios uses position absolute
// and doesn't have correct width (overflows to the right over ion-buttons)
.ios .center-ion-title {
  position: static;
  padding: 0;
}

.height100 {
  height: 100%;
}

.not-last-margin-bottom {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.mercado-pago-result-animation {
  height: 200px !important;
  @media (max-width: 480px) {
    height: 150px !important;
  }
}

.generic-no-mt {
  --appo-generic-info-margin-top: 0;
}

.business-countdown {
  background: #EDF0FE;
  border-radius: 20px;
}
