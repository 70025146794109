@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}

@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.rotate-infinite {
  $duration: 2s;
  -webkit-animation: spin $duration linear infinite;
  -moz-animation: spin $duration linear infinite;
  animation: spin $duration linear infinite;
}
