.ion-color-appo-blue {
  --ion-color-base: var(--ion-color-appo-blue);
  --ion-color-base-rgb: var(--ion-color-appo-blue-rgb);
  --ion-color-contrast: var(--ion-color-appo-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-appo-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-appo-blue-shade);
  --ion-color-tint: var(--ion-color-appo-blue-tint);
}

.ion-color-appo-white {
  --ion-color-base: var(--ion-color-appo-white);
  --ion-color-base-rgb: var(--ion-color-appo-white-rgb);
  --ion-color-contrast: var(--ion-color-appo-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-appo-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-appo-white-shade);
  --ion-color-tint: var(--ion-color-appo-white-tint);

  ion-icon {
    color: var(--gray-color);
  }
}

.ion-color-appo-red {
  --ion-color-base: var(--ion-color-appo-red);
  --ion-color-base-rgb: var(--ion-color-appo-red-rgb);
  --ion-color-contrast: var(--ion-color-appo-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-appo-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-appo-red-shade);
  --ion-color-tint: var(--ion-color-appo-red-tint);
}

.ion-color-appo-gray {
  --ion-color-base: var(--ion-color-appo-gray);
  --ion-color-base-rgb: var(--ion-color-appo-gray-rgb);
  --ion-color-contrast: var(--ion-color-appo-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-appo-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-appo-gray-shade);
  --ion-color-tint: var(--ion-color-appo-gray-tint);
}

.ion-color-appo-green {
  --ion-color-base: var(--ion-color-appo-green);
  --ion-color-base-rgb: var(--ion-color-appo-green-rgb);
  --ion-color-contrast: var(--ion-color-appo-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-appo-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-appo-green-shade);
  --ion-color-tint: var(--ion-color-appo-green-tint);
}

.ion-color-favorites {
  --ion-color-base: var(--ion-color-favorites);
  --ion-color-base-rgb: var(--ion-color-favorites-rgb);
  --ion-color-contrast: var(--ion-color-favorites-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorites-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorites-shade);
  --ion-color-tint: var(--ion-color-favorites-tint);
}
