.mapboxgl-ctrl-top-right {
  width: 100% !important;
  padding: 0 10px;
}

.mapboxgl-ctrl {
  margin: 10px 0 0 0 !important;
  border-radius: var(--appo-border-radius) !important;
  width: 100% !important;
  background: var(--global-background-color) !important;
}

.mapboxgl-ctrl-geocoder--input {
  font-family: var(--ion-font-family) !important;
}

.mapboxgl-ctrl-geocoder--button {
  background: var(--global-background-color) !important;
}

.suggestions-wrapper {
  .suggestions {
    border-radius: var(--appo-border-radius) !important;
    font-family: var(--ion-font-family) !important;

    .mapbox-gl-geocoder--no-results {
      display: none !important; // Keep this and remove it in the appoGeoCoderErrorTextHandler
    }
  }
}


