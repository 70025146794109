@keyframes scaleIn {
    from {
        transform: scale(.3, .3);
        opacity: .6;
    }
    to {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.circle {
    border-radius: 50%;
    background-color: var(--main-color);
    width: 25px;
    height: 25px;
    position: absolute;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}
